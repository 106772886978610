import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Stack, Typography, TextField } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { styled } from '@mui/system';
import { CallOutlined } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import ContactUsBackground from '../images/contactUs_background.webp';
import DynamicAspectRatioImage from './DynamicAspectRatioImage';
import MainHeader from './MainHeader';
import useResponsive from '../hooks/useResponsive';
import MainFooter from './MainFooter';
import { GOOGLE_MAP_API_KEY } from '../config';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%; /* Full width */
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const ContactUs = () => {
    const mdUp = useResponsive('up', 'md');
    const smDown = useResponsive('down', 'sm');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [submissionMessage, setSubmissionMessage] = useState('');

    const registerHereCallback = (selectedClub) => {

    }

    const handleSubmit = () => {
        if (name && email && subject && message) {
            // Perform your submission logic here, e.g., send data to server
            setSubmissionMessage('Your query is recorded. We will contact you soon.');
            // Reset form fields
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
        } else {
            setSubmissionMessage('Please fill in all fields.');
        }
    };

    const mapContainerStyle = {
        width: '100%',
        height: smDown ? '250px' : '500px', // Set a fixed height for the map container
        borderRadius: '8px',
        margin: '8px',
    };

    const randomCoordinate = (min, max) => {
        return Math.random() * (max - min) + min;
    };
    const defaultCenter = {
        lat: randomCoordinate(-90, 90),
        lng: randomCoordinate(-180, 180),
    };

    return (
        <div>
            <MainHeader />
            <Grid container>
                <Grid item xs={12}>
                    <Box position="relative" width="100%" height="100%" >
                        <img src={ContactUsBackground} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: smDown && 'right' }} />
                        <Stack
                            position={mdUp && 'absolute'}
                            bottom={mdUp && 120}
                            left={mdUp && 150}
                            flexDirection={'column'}
                            // alignItems={smDown ? 'center' : 'flex-start'}
                            justifyContent={'center'}
                            sx={{ padding: '20px', borderRadius: '8px' }}
                        >
                            <Stack flexDirection={smDown ? 'row' : 'column'} alignItems={smDown && 'center'} justifyContent={'center'}>
                                <Typography textTransform={smDown && 'uppercase'} marginRight={smDown && 1} variant={smDown ? 'h3' : 'h1'} color={smDown ? 'var(--Green-100, #00A76F)' : 'var(--Secondary-brand-color, #62ECBC);'} lineHeight={!smDown && '62px'}> Where</Typography>
                                <Typography textTransform={smDown && 'uppercase'} variant={smDown ? 'h3' : 'h1'} color={smDown ? 'black' : 'white'}>
                                    to find us?
                                </Typography>
                            </Stack>
                            {smDown && <Typography textAlign={'center'} variant='caption'>Don’t hesitate to reach out to us</Typography>}

                            <Stack marginTop={'16px'} alignItems={'center'} flexDirection={smDown ? 'column' : 'row'}>
                                <Stack alignItems={smDown && 'center'} marginBottom={smDown && '16px'}>
                                    <Typography color={smDown ? 'black' : 'white'} fontWeight={smDown && 'bold'} marginBottom={smDown && '8px'} marginRight={!smDown && '200px'}><CallOutlined sx={{ color: smDown ? 'black' : 'white', fontSize: '16px', marginRight: '4px' }} color={smDown ? 'black' : 'white'} />Call Us</Typography>
                                    <Typography color={smDown ? 'black' : 'white'}>+91 9875286978</Typography>
                                </Stack>
                                <Stack alignItems={smDown && 'center'} marginBottom={smDown && '16px'}>
                                    <Typography color={smDown ? 'black' : 'white'} fontWeight={smDown && 'bold'} marginBottom={smDown && '8px'}><MailOutlineIcon sx={{ color: smDown ? 'black' : 'white', fontSize: '16px', marginRight: '4px' }} color={smDown ? 'black' : 'white'} />Email Us</Typography>
                                    <Typography color={smDown ? 'black' : 'white'}>contact@krida.club</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid flexDirection={smDown && 'column-reverse'} container spacing={2} marginTop={smDown ? 28 : 8}>
                <Grid item xs={12} md={6}>
                    <Paper>
                        <Grid container direction="column" rowSpacing={2} padding={smDown ? 2 : 12}>
                            <Typography marginBottom={smDown && '16px'} lineHeight={'1.4'} variant={'h4'}>Feel free to inquire about anything.
                                We'll be glad to hear from you!!</Typography>
                            <Grid item>
                            <TextField
                                sx={{ fontSize: '16px' }}
                                label="Name"
                                variant="outlined"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            </Grid>
                            <Grid item>
                            <TextField
                                sx={{ fontSize: '16px' }}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            </Grid>
                            <Grid item>
                            <TextField
                                sx={{ fontSize: '16px' }}
                                label="Subject"
                                variant="outlined"
                                fullWidth
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                            </Grid>
                            <Grid item>
                            <Textarea
                                aria-label="minimum height"
                                minRows={3}
                                sx={{ fontSize: '16px' }}
                                placeholder="Enter your message area"
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            </Grid>
                            <Button
                                variant='contained'
                                // fullWidth={smDown ? true : false}
                                sx={{
                                    width: smDown ? '100%' : '120px',
                                    marginTop: '32px',
                                    marginBottom: smDown && '16px',
                                    backgroundColor: 'var(--global-inherit-bg, #212B36)',
                                    fontSize: smDown ? '12px' : '14px',
                                    color: 'white'
                                }}
                                onClick={handleSubmit}
                                >
                                Submit Now
                            </Button>
                            {submissionMessage && <Typography color="success">{submissionMessage}</Typography>}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }} item xs={12} md={6}>
                    <Paper sx={{ width: '-webkit-fill-available' }}>
                        <Box pb={8}>
                            <LoadScript
                                id="script-loader"
                                googleMapsApiKey={GOOGLE_MAP_API_KEY}
                                region="IN"
                            >
                                <div style={{ paddingRight: '16px' }}>
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        center={defaultCenter}
                                        zoom={10} // Adjust the initial zoom level as needed
                                    >
                                        <Marker position={defaultCenter} />
                                    </GoogleMap>
                                </div>
                            </LoadScript>
                        </Box>
                        {/* <div>
                            <img src={ContactUsBackground} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div> */}
                    </Paper>
                </Grid>
            </Grid>
            <MainFooter />
        </div>
    );
};


export default ContactUs;
