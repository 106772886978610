import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DynamicAspectRatioImage from './DynamicAspectRatioImage';
import useResponsive from '../hooks/useResponsive';
import Landing_One from '../images/landing_one.webp';
import success_Msg from '../images/success-msg.png';
import axios from './../utils/axios';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(12),
    paddingTop: 0,
    background: 'var(--Primary-1, #081F40)',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(0),
    },
  },
  root: {
    padding: theme.spacing(12),
  },
  formContainer: {
    padding: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formField: {
    marginBottom: theme.spacing(1),
  }
}));

const noOfCenter = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '1-5',
    label: '1-5',
  },
  {
    value: '5-10',
    label: '5-10',
  },
  {
    value: 'More than 10',
    label: 'More than 10',
  },
];

const noOfPlayers = [
  {
    value: '0-50',
    label: '0-50',
  },
  {
    value: '50-100',
    label: '50-100',
  },
  {
    value: '100-500',
    label: '100-500',
  },
  {
    value: 'More than 500',
    label: 'More than 500',
  },
];

const ContactUsPage = () => {
  const classes = useStyles();
  const mdUp = useResponsive('up', 'md');
  const smDown = useResponsive('down', 'sm');
  const [successMsg , setSuccessMsg] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    company: '',
    numOfCenters: '1',
    numOfPlayers: '0-50',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    company: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (formData.firstName.trim() === '') {
      errors.firstName = true;
    }
    if (formData.phone.trim() === '') {
      errors.phone = true;
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        email: formData.email,
        company: formData.company,
        numOfCenters: formData.numOfCenters,
        numOfPlayers: formData.numOfPlayers,
      };
      try {
        const response = await axios.post('/request-demo', payload);
        console.log('Response:', response.data);
        setSuccessMsg(true);
      } catch (error) {
        console.error('Error:', error);
      }
      console.log('Payload:', payload);
    }
  };
  

  return (
      <Box>
          {successMsg ? (<>
              <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
                  <DynamicAspectRatioImage
                      size={'40%'}
                      uri={success_Msg}
                      alt="Success Image"
                      containerStyle={{ borderRadius: smDown ? '16px' : '32px', height: '250px' }}
                  />
                  <Typography sx={{ mt: '16px', mr:'2px', ml:'2px' ,fontWeight: '600', fontSize:'22px' }}>Thank you</Typography>
                  <Typography sx={{ mt: '8px', mr:'2px', ml:'6px' ,fontWeight: '400', fontSize:'18px' }} variant="body1">Submitted successfully. We will connect with you soon.</Typography>
              </Box>
          </>) :
              (<Grid container sx={{ marginTop: smDown && 6 }} spacing={2}>
                  <><Grid item xs={12} md={6}>
                      <Box sx={{ padding: 1, display:'flex',justifyContent: 'center', alignItems:'center' }}>
                          <DynamicAspectRatioImage
                              size={'75%'}
                              uri={Landing_One}
                              alt="Landing Image"
                              containerStyle={{ borderRadius: smDown ? '16px' : '32px', height: '250px' }}
                          />
                      </Box>
                  </Grid>

                      <Grid item xs={12} md={6}>
                          <Box className={classes.formContainer}>
                              <Typography variant="h4">Book Demo</Typography>
                              <Typography variant="body2" marginBottom={4} color={'darkslategray'}>
                                  Kindly provide your information
                              </Typography>

                              <form onSubmit={handleSubmit}>
                                  <Grid container direction="column" spacing={1}>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              size="medium"
                                              label="First Name"
                                              variant="outlined"
                                              fullWidth
                                              required
                                              name="firstName"
                                              value={formData.firstName}
                                              onChange={handleChange}
                                              error={formErrors.firstName}
                                          />
                                      </Grid>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              size="medium"
                                              label="Last Name"
                                              variant="outlined"
                                              fullWidth
                                              name="lastName"
                                              value={formData.lastName}
                                              onChange={handleChange}
                                              error={formErrors.lastName}
                                          />
                                      </Grid>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              size="medium"
                                              label="Mobile Number"
                                              variant="outlined"
                                              fullWidth
                                              required
                                              name="phone"
                                              value={formData.phone}
                                              onChange={handleChange}
                                              error={formErrors.phone}
                                          />
                                      </Grid>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              size="medium"
                                              label="Email"
                                              variant="outlined"
                                              defaultValue=""
                                              fullWidth
                                              name="email"
                                              value={formData.email}
                                              onChange={handleChange}
                                          />
                                      </Grid>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              size="medium"
                                              label="Company"
                                              variant="outlined"
                                              fullWidth
                                              name="company"
                                              value={formData.company}
                                              onChange={handleChange}
                                              error={formErrors.company}
                                          />
                                      </Grid>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              id="outlined-select-center"
                                              select
                                              label="Select"
                                              defaultValue="5-10"
                                              helperText="Please select number of center"
                                              variant="outlined"
                                              fullWidth
                                              name="numOfCenters"
                                              value={formData.numOfCenters}
                                              onChange={handleChange}
                                          >
                                              {noOfCenter.map((option) => (
                                                  <MenuItem key={option.value} value={option.value}>
                                                      {option.label}
                                                  </MenuItem>
                                              ))}
                                          </TextField>
                                      </Grid>
                                      <Grid item className={classes.formField}>
                                          <TextField
                                              id="outlined-select-players"
                                              select
                                              label="Select"
                                              defaultValue="1-200"
                                              helperText="Please select number of player"
                                              variant="outlined"
                                              fullWidth
                                              name="numOfPlayers"
                                              value={formData.numOfPlayers}
                                              onChange={handleChange}
                                          >
                                              {noOfPlayers.map((option) => (
                                                  <MenuItem key={option.value} value={option.value}>
                                                      {option.label}
                                                  </MenuItem>
                                              ))}
                                          </TextField>
                                      </Grid>
                                      <Grid item>
                                          <Button
                                              type="submit"
                                              size="large"
                                              variant="contained"
                                              sx={{
                                                  backgroundColor: '#212B36',
                                                  boxShadow: 'none',
                                                  ':hover': {
                                                      backgroundColor: '#212B36',
                                                  },
                                              }}
                                              color="primary"
                                              fullWidth
                                          >
                                              SUBMIT
                                          </Button>
                                      </Grid>
                                  </Grid>
                              </form>
                          </Box>
                      </Grid></>
              </Grid>)}
      </Box>
  );
};

export default ContactUsPage;
