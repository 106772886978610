import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Typography, Stack } from '@mui/material';
// hooks
import useOffSetTop from '../hooks/useOffSetTop';
import useResponsive from '../hooks/useResponsive';
// utils
import cssStyles from '../utils/cssStyles';
// config
import { HEADER } from '../config';
// components
import Logo from '../components/Logo';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

const MainHeader = () => {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <AppBar position={'fixed'} sx={{ bgcolor: 'transparent', boxShadow:'none' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          // maxWidth={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
          <Stack alignItems={'center'} flexDirection={'row'}><Logo size={isDesktop ? 64 : 48} /><Typography pl={.5} color={isOffset ? 'black' : 'var(--Secondary-brand-color, #62ECBC)'} >Krida</Typography></Stack>

          {isDesktop && <Box sx={{ flexGrow: 1 }} />}

          {/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
          {/* {isDesktop && <Button
            variant="text"
            target="_blank"
            rel="noopener"
            href=""
            sx={{ marginRight: '10px', color: isOffset ?'black' : 'var(--Secondary-brand-color, #62ECBC)', }}
          >
            Login
          </Button>}

          {isDesktop && <Button
            variant="text"
            target="_blank"
            rel="noopener"
            href=""
            sx={{ marginRight: '10px', color: isOffset ?'black' : 'var(--Secondary-brand-color, #62ECBC)', }}
          >
            Sign up
          </Button>} */}

          {/* <Button
            variant="text"
            target="_blank"
            rel="noopener"
            href=""
            sx={{color: isOffset ?'black' : 'var(--Secondary-brand-color, #62ECBC)',}}
          >
            Get App
          </Button> */}

        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
export default MainHeader
