import { Box } from '@mui/material';
import React from 'react';

const DynamicAspectRatioImage = ({
    uri,
    size = 80,
    onImageContainerDimensionChange,
    containerStyle = {}
}) => {

    React.useEffect(() => {
        if (onImageContainerDimensionChange) {
            onImageContainerDimensionChange({ width: size, height: size });
        }
    }, [size, onImageContainerDimensionChange]);

    return (
        <Box sx={[containerStyle, {
            width: size,
            height: size,
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        }]}>
            {uri && (
                <img
                    src={uri}
                    alt=""
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        display: 'block',
                        objectFit: 'cover',
                        margin: 'auto'
                    }}
                />
            )}
        </Box>
    );
};

export default DynamicAspectRatioImage;
