// import { m } from 'framer-motion';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/system';
import {
  Button, Box, Link, Container, Grid, Typography, Stack, Card, Tabs,
  Tab, ListItem, List
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import CarouselV2 from '../krida/carousel/carousel-v2';
import DynamicAspectRatioImage from './DynamicAspectRatioImage';
import KridaLabel from '../krida/label/KridaLabel';
// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
// import { Icon } from '@iconify/react';
// import { useDispatch, useSelector } from 'react-redux';
// import Image from '../../components/Image';

import "./LandingPage.css";
import './fonts.css';

import TRUSTED_LOGO from '../images/trusted_partner_logo.webp'
import CarouselAnimation from '../krida/carousel/carousel-animation';
import CarouselComponent from './CarouselComponent';



// routes
// import { PATH_DASHBOARD } from '../../routes/paths';

// components
// import { MotionContainer, varFade } from '../../components/animate';
import MainFooter from './MainFooter';

// import Header from '../../layouts/dashboard/header/index';

import useResponsive from '../hooks/useResponsive';
import WelcomeCarousel from './WelcomeCarousel';
import Image from '../components/Image';
// import { fetchEventData } from '../../redux/slices/registration';


// import SportsCenters from '../../layouts/dashboard/SportsCenters';
// import InputDropDown from '../../layouts/dashboard/InputDropDown';

// images
import Badminton from '../images/badmintonPlayerPage1.jpg';
import Cricket from '../images/cricketPage1.jpg';
import Basketball from '../images/basketballPage1.jpg';
import Swimming from '../images/swimmingPlayerPage1.jpg';
import Football from '../images/footballPage1.jpg';
import Skater from '../images/skaterPage1.jpg';
import Tennis from '../images/lawnTennisPage1.jpg';
import Devices from '../images/devicesPage1.jpg';
import KridaLogo from '../images/KridaPage1.svg';
import Landing_One from '../images/landing_one.webp'
import FINANCE_MANAGEMENT from '../images/finance_management.webp'
import OPTIMIZE_OPERATIONAL_EFFICIENCY from '../images/optimize_operational_efficiency.webp'
import PERFORMANCE_ANALYTICS from '../images/performance_analytics.webp'
import SEAMLESS_COMMUNICATION from '../images/seamless_communication.webp'
import KRIDA_SAMPLE_ECOSYSTEM from '../images/krida_ecosystem.webp'
import PSM_LOGO from '../images/logo_PSB.webp';
import AFA_LOGO from '../images/logo_AFA.svg';
import RBA_LOGO from '../images/logo_RBA.svg';
import ARIES_LOGO from '../images/aries.webp'
import SASA_LOGO from '../images/sasa.webp'
import BACKGROUND from '../images/background.webp'
import TESTIMONIAL_BACKGROUND from '../images/testimonial_background.webp'
import MainHeader from './MainHeader';
import { FastForward } from '@mui/icons-material';
import StickyTabs from './StickyTab';
import CustomModal from './CustomModal';
import ContactUsPage from './ContactUsPage';
import GREY from '../theme/palette'
import ERROR from '../theme/palette'
// fonts
// import Poppins from '../../../public/fonts/Poppins-Regular.ttf'


// ----------------------------------------------------------------------

const product = {
  landingImages: [
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
      "title": "Harry Potter and the Deathly Hallows - Part 2",
      "description": "Apply These 7 Secret Techniques To Improve Event",
      "image": ARIES_LOGO
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
      "title": "Disney Zombies 2",
      "description": "Believing These 7 Myths About Event Keeps You From Growing",
      "image": SASA_LOGO
    },

  ]
}
const useStyles = makeStyles((theme) => ({
  landingContainer: {
    padding: theme.spacing(12),
    paddingTop: 0,
    background: 'var(--Primary-1, #081F40)',
    // background: 'linear-gradient(115deg, #000101 0%, #00204F 100%)',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      // background: 'linear-gradient(115deg, #000101 0%, #00204F 100%)',
      padding: theme.spacing(0),
    },
    eventContainer: {
      padding: theme.spacing(12),
      height: '500px',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    testimonialContainer: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(8),
      backgroundColor: 'var(--Primary-1, #081F40)',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
        backgroundColor: 'var(--Primary-1, #081F40)',
        padding: theme.spacing(0),
      },
    },
  },
  tabContainer: {
    position: 'sticky',
    top: '65px',
    zIndex: '1000',
    backgroundColor:'white'
  },
}));

const NoDataContainer = () => (
  <Box height="64vh" display="flex" alignItems="center" justifyContent="center" border={"1px solid #EAEAEA"} borderRadius={'16px'}>
    <Typography variant="h6" color="textSecondary">
      No Events available
    </Typography>
  </Box>
);

const TabData = ({ label, value, image, data }) => {
  const mdUp = useResponsive('up', 'md')
  const smDown = useResponsive('down', 'sm')

  return (
    <Box height={mdUp && "70vh"} display="flex" alignItems="center" justifyContent="center" >
      <Grid container spacing={2} flexDirection={smDown && 'column-reverse'}>
        {/* Typography Texts */}
        <Grid item xs={12} sm={8}>
          <Box display="flex" flexDirection="column" height="100%" justifyContent="center" alignItems={mdUp && 'center'}>
            <Typography
              lineHeight={1.2}
              width={mdUp ? '40vw' : '100%'}
              textAlign={'center'}
              variant={smDown ? 'h5' : "h3"}>
              {data?.heading}
            </Typography>
            <List sx={{ listStyleType: 'disc', p: smDown ? '16px 0px' : 4, width: mdUp ? '30vw' : '100%' }}>
              {data?.content?.map(item =>
                <ListItem >
                  <FastForward sx={{ marginRight: '12px' }} />
                  <Typography variant={smDown ? 'caption' : "body1"}>
                    {item}
                  </Typography>
                </ListItem>
              )}
            </List>
          </Box>
        </Grid>
        {/* Image */}
        <Grid item xs={12} sm={4}>
          <Box display="flex" justifyContent="center">
            <DynamicAspectRatioImage uri={image} alt="Feature snapshots" size={mdUp ? 500 : 250} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const LandingPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const smDown = useResponsive('down', 'sm');
  const mdUp = useResponsive('up', 'md');
  const [openModal, setOpenModal] = useState(false);
  const onClose = (val) => {
    setOpenModal(val);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabData = [
    {
      key: 'Financial Management',
      image: FINANCE_MANAGEMENT,
      data: {
        heading: `Financial management made easy!!`,
        content: [`Accept payments at 0% commission  directly into your account`, `Comprehensive management of player subscriptions, invoices, and reminders`],
      }
    },
    {
      key: 'Operational Efficiency',
      image: OPTIMIZE_OPERATIONAL_EFFICIENCY,
      data: {
        heading: `Optimize operational efficiency`,
        content: [`Attendance system for trainers and role-based access control`, `Automated scheduling, capacity management, and attendance tracking`],
      }
    },
    {
      key: 'Performance & Analytics',
      image: PERFORMANCE_ANALYTICS,
      data: {
        heading: `Performance and analytics simplified`,
        content: [`Sports-specific assessments with data import and sharing capabilities`, `Financial, player, and attendance analysis for informed decision-making`],
      }
    },
    {
      key: 'Communication and Engagement',
      image: SEAMLESS_COMMUNICATION,
      data: {
        heading: `Seamless communication & effective engagement`,
        content: [`Payment and batch session reminders for players and trainers`, ` Instant notifications and communication channel for club users`],
      }
    }
  ];

  const tabsInScrollData = tabData.map(item => ({
    text: item.key,
    component: (
      <TabData
        label={item.key}
        value={value}
        image={item.image}
        data={item.data}
      />
    )
  }));

  return (
    <div>
      <MainHeader />
      {openModal && <CustomModal showModal={openModal} modalClose={onClose}><ContactUsPage/></CustomModal>}
      <Grid container className={classes.landingContainer}>
        <Grid container sx={{ marginTop: smDown ? 6 : 12 }} spacing={2} >
          {/* Left side with image */}
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: 1, justifyContent: 'center' }}>
              <DynamicAspectRatioImage
                size={'100%'}
                uri={Landing_One}
                alt="Landing Image"
                containerStyle={{ borderRadius: smDown ? '16px' : '32px', height: '250px' }}
              />
            </Box>
          </Grid>

          {/* Right side container using Grid */}
          <Grid item xs={12} md={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box className="right-side">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  {/* Container on the right side */}
                  <Box
                    display="flex"
                    flexDirection={'column'}
                    alignItems={smDown ? "center" : "start"}
                    justifyContent={'center'}
                    height="100%"
                  >
                    <Typography textAlign={smDown && 'center'} alignItems={smDown && 'center'} justifyContent={smDown && 'center'} marginRight={smDown && 1} marginLeft={smDown && 1} color={'white'} lineHeight={smDown ? '38.41px' : '54px'} fontWeight={'600'} fontSize={smDown ? '33px' : '36px'}>
                      <span style={{ color: 'var(--Secondary-brand-color, #62ECBC)' }}>GROW </span>YOUR SPORTS ACADEMY WITH THE POWER OF AUTOMATION</Typography>
                    <Typography textAlign={smDown && 'center'} alignItems={smDown && 'center'} justifyContent={smDown && 'center'} marginTop={smDown ?
                      '8px' : '8px'} lineHeight={smDown ? '24px' : '38px'} marginRight={smDown && 1} marginLeft={smDown && 1}
                      color='var(--White-90, rgba(255, 255, 255, 0.90));;' fontWeight={'400'} fontSize={smDown ? '18px' : '24px'}>
                      Save upto 30 hours per month using our solutions
                    </Typography>
                    <Typography
                      textAlign={smDown && 'center'}
                      alignItems={smDown && 'center'}
                      justifyContent={smDown && 'center'}
                      sx={{
                        mt: '16px',
                        fontWeight: '400',
                        fontSize:'14px'
                      }}
                      marginRight={smDown && 1} marginLeft={smDown && 1}
                      variant={smDown ? 'caption' : 'subtitle1'}
                      color={'var(--White, #FFF)'}>
                      We understand the intricate demands of running a sports academy. Our tailored solutions are designed to simplify your daily operations, making management a breeze. Join the countless academy owners who have embraced Krida's innovative technology to enhance efficiency and elevate their academies to new heights
                    </Typography>
                    <Button onClick={() => setOpenModal(true)}
                     variant='contained' 
                     sx={{ padding: smDown ? '12px 28px' : '16px 40px', marginTop: '32px', marginBottom: smDown && '16px', backgroundColor: 'var(--Secondary-brand-color, #62ECBC)', fontSize: smDown ? '12px' : '16px', color: 'var(--Primary-1, #081F40)' }}>Request a demo</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        className="grid-section"
        style={{
          background: '#919EAB14',
          marginTop: '0px',
          marginBottom: smDown ? '50px' : '80px',
          alignItems: 'center',
          justifyContent: 'center',
          overflow:'hidden',
        }}
      >
        <Stack sx={{ padding: smDown ? '80px 0px' : '80px 40px' }} spacing={2} >
          <Typography sx={{ margin: '8px', padding: '32px', textAlign:'center'}}  color={'var(--Grey-60, rgba(0, 0, 0, 0.6))'}
            variant={smDown ? 'h3' : 'h2' }  fontWeight= {400} >
           <span style={{ display:mdUp &&'block' }} >Serving more than</span>
            <span style={{  margin: '7px', color: '#00A76F', fontWeight: 600 }}>
               45+ venues
            </span>
            <span>in more than </span>
            <span style={{ color: '#00A76F', fontWeight: 600 }} >
              10+ cities
            </span>
          </Typography>

            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop:'19px'}}>
              <DynamicAspectRatioImage size={25}
                uri={TRUSTED_LOGO} />
              <Typography maxWidth={'100%' } lineHeight={'1.18'} marginLeft={'10px'} color='black' variant={smDown ? 'h7' : 'h5'} fontWeight={400}>
              OUR TRUSTED PARTNERS
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={smDown ? 1.5: 3} paddingLeft={smDown &&'8%'} paddingRight={smDown &&'8%' }>
              <Stack marginLeft={smDown ? '0' : '32px'} justifyContent={'center'} alignItems={'center'} sx={{
                borderRadius: smDown ? 1: 1.5,
                overflow: 'hidden',
                backgroundColor: 'white',
                aspectRatio: 1 / 1,
                p: mdUp ? 2 : 1,
                boxShadow: 6,
               height: '50%',
                width: '50%'
              }}>
                <DynamicAspectRatioImage size={mdUp ? 80 : 48}
                  uri={PSM_LOGO} />
              </Stack>
              <Stack  justifyContent={'center'} alignItems={'center'} sx={{
                borderRadius: smDown ? 1: 1.5,
                overflow: 'hidden',
                backgroundColor: 'white',
                aspectRatio: 1 / 1,
                p: mdUp ? 2 : 1,
                boxShadow: 6,
                height: '50%',
                width: '50%'
              }}>
                <DynamicAspectRatioImage size={mdUp ? 80 : 48}
                  uri={ARIES_LOGO} />
              </Stack>
              <Stack justifyContent={'center'} alignItems={'center'} sx={{
                borderRadius: smDown ? 1: 1.5,
                overflow: 'hidden',
                backgroundColor: 'white',
                aspectRatio: 1 / 1,
                p: mdUp ? 2 : 1,
                boxShadow: 6,
                height: '50%',
                width: '50%'
              }}>
                <DynamicAspectRatioImage size={mdUp ? 80 : 48}
                  uri={SASA_LOGO} />
              </Stack>
              <Stack justifyContent={'center'} alignItems={'center'} sx={{
                borderRadius: smDown ? 1: 1.5,
                overflow: 'hidden',
                backgroundColor: 'white',
                aspectRatio: 1 / 1,
                p: mdUp ? 2 : 1,
                boxShadow: 6,
                height: '50%',
                width: '50%'
              }}>
                <DynamicAspectRatioImage size={mdUp ? 80 : 48}
                  uri={AFA_LOGO} />
              </Stack>
              <Stack justifyContent={'center'} alignItems={'center'} sx={{
                borderRadius: smDown ? 1: 1.5,
                overflow: 'hidden',
                backgroundColor: 'white',
                aspectRatio: 1 / 1,
                p: mdUp ? 2 : 1,
                boxShadow: 6,
                height: '50%',
                width: '50%',
              }}>
                <DynamicAspectRatioImage size={mdUp ? 80 : 48}
                  uri={RBA_LOGO} />
              </Stack>
            </Stack>
        </Stack>
      </Grid>
      <StickyTabs tabsInScroll={tabsInScrollData} />

      {/* <Grid container className={classes.eventContainer} id="eventContainer">
        <Grid item xs={12} md={12} sx={{ p: smDown ? 4 : 10 }}>
          <Box className={classes.tabContainer}>
          <Typography variant='h4'>WHAT WE OFFER</Typography>
            <Tabs variant='scrollable' value={value} onChange={handleChange} centered>
              {tabData.map((item, index) => (
                <Tab key={index} sx={{ fontSize: smDown && '12px', marginRight: smDown && '24px' }} label={item.key} />
              ))}
            </Tabs>
            </Box>
            {tabData.map((item, index) => (
              <TabPanel key={index} value={value} index={index}>
                <TabData label={item.key} value={value} image={item.image} data={item.data} />
              </TabPanel>
            ))}
        </Grid>
      </Grid> */}

      {/* <Grid
        container
        sx={{
          height: '800px',
          backgroundColor: 'var(--Primary-1, #081F40)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={12} sx={{ p: smDown ? 4 : 10 }}>
          <Typography sx={{ textAlign: 'center', paddingTop: '50px' }} variant='h4' color='white'>Here is what our <span style={{ color: 'var(--Secondary-brand-color, #62ECBC)' }}>Clients</span> are saying <span style={{ color: 'var(--Secondary-brand-color, #62ECBC)' }}>About us</span></Typography>
          <Box position={'relative'} height={'400px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CarouselAnimation data={(['https://www.pexels.com/photo/green-and-blue-peacock-feather-674010/'] || []).map((img, index) => {
              return {
                // coverUrl: img,
                title: 'summer camp',
                description: `Unleash your child potential ${index}`
              }
            })} />
          </Box>
        </Grid>
      </Grid> */}

      <Grid flexDirection={smDown && 'column-reverse'} container sx={{ marginTop: smDown ? 2 : 2,justifyContent: smDown ? 'flex-start' : 'center'}} columnSpacing={smDown ? 0 : 10}>
        <Grid item xs={12} md={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Box className="right-side">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box
                  flexDirection={'column'}
                  alignItems="center"
                  justifyContent={'center'}
                  padding={2}
                >
                  <Typography textAlign={smDown && 'center'} variant='h3' color={'var(--Green-100, #00A76F)'}>
                    Ready to transform your sports academy
                  </Typography>
                  <Typography textAlign={smDown && 'center'} color='var(--Grey-70, rgba(0, 0, 0, 0.70));' variant='body2'>
                    Contact the Krida team to explore how our tailored solutions can transform your academy's operations. Join us on the path to streamlined and hassle-free academy management – your journey begins with a simple click or call.
                  </Typography>
                  <Stack sx={{ flexDirection: 'row', justifyContent: smDown && 'center' }}>
                    <Button onClick={() => { window.open('https://pui9keyydqo.typeform.com/to/B4npHFCi') }} variant='contained' sx={{ marginTop: '32px', backgroundColor: 'var(--Primary-1, #081F40)' }}>Book a demo</Button>
                    <Button  onClick={() => {navigate('/contact-us')}} variant='outlined' sx={{ marginTop: '32px', marginLeft: '16px', }}>Contact Us</Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box className="left-side"
            style={{
              alignItems: 'center',
              backgroundImage: `url(${BACKGROUND})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              justifyContent:smDown && 'center',
              display:'flex',
            }}>
            <DynamicAspectRatioImage
              size={mdUp ? 558 : 280}
              uri={KRIDA_SAMPLE_ECOSYSTEM}
              alt="KRIDA Sample Image"
            />
          </Box>
        </Grid>
      </Grid>
      {/*
      <Grid container sx={{ marginTop: 10, marginLeft: 10 }} spacing={2}>
 
        <Grid item xs={12} md={5}>
          <Box className="left-side" style={{
            backgroundImage: `url(${BACKGROUND})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
            <DynamicAspectRatioImage
              size={mdUp ? 558 : 300}
              uri={KRIDA_SAMPLE_ECOSYSTEM}
              alt="KRIDA Sample Image"
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Box className="right-side">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box
                  flexDirection={'column'}
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <Typography variant='h3' color={'var(--Green-100, #00A76F)'}>
                    Ready to transform your sports academy
                  </Typography>
                  <Typography color='var(--Grey-70, rgba(0, 0, 0, 0.70));' variant='body2'>
                    Contact the Krida team to explore how our tailored solutions can transform your academy's operations. Join us on the path to streamlined and hassle-free academy management – your journey begins with a simple click or call.
                  </Typography>
                  <Stack sx={{ flexDirection: 'row' }}>
                    <Button variant='contained' sx={{ marginTop: '32px', backgroundColor: 'var(--Primary-1, #081F40)' }}>Book a demo</Button>
                    <Button variant='outlined' sx={{ marginTop: '32px', marginLeft: '16px', }}>Contact Us</Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid> */}
      <MainFooter />
    </div>
  )
}

export default LandingPage
