import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import './App.css';
import LandingPage from './components/LandingPage';
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import Privacy from './components/Privacy';
import ThemeProvider from './theme';

const router = createBrowserRouter([
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/",
    element: <LandingPage />
  },
  {
    path: "/about-us",
    element: <AboutUs />
  },
  {
    path: "/contact-us",
    element: <ContactUs />
  },
]);


function App() {
  useEffect(() => {
    (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/aju396u8'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
  }, []);
  return (
    <div className="App">
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
